import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"

export default () => (
  <Container>
    <Helmet>
      <title>Screenings | An Exquisite Meal</title>
      <meta
        name="description"
        content="Upcoming screenings for An Exquisite Meal."
      />
    </Helmet>

    <div class="sub-container-page">
      <div class="body-content">
        <h2 class="headline">Upcoming screenings.</h2>

        <div class="description">
          <h3>
            Cinequest, San Jose, CA (
            <a href="https://payments.cinequest.org/websales/pages/info.aspx?evtinfo=114108~78899376-35a9-4153-8303-e1557be2dc32&epguid=d52499c1-3164-429f-b057-384dd7ec4b23&#.XjC34RNKjyg">
              tickets
            </a>
            )
          </h3>

          <p>March 7</p>
          <p>March 8</p>
          <p>March 10</p>
          <p>March 14</p>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </Container>
)
